import { nanoid } from 'nanoid'

export default {
  label: 'Default',
  value: 'default',
  preset: {
    items: [
      {
        id: nanoid(),
        enabled: true,
        label: 'Home',
        action: {
          enabled: true,
          type: 'page',
          value: 'home',
        },
        icon: 'v2/heroic/home',
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Rewards',
        action: {
          enabled: true,
          type: 'page',
          value: 'giftcards',
        },
        description:
          'Access endless benefits when shopping with leading brands and service providers!',
        icon: 'v2/box-icons/bx-gift',
        children: [
          {
            id: nanoid(),
            enabled: true,
            label: 'Gift Cards',
            action: {
              enabled: true,
              type: 'page',
              value: 'giftcards',
              appearance: 'link',
            },
            icon: 'v2/custom/egiftcard',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Online Offers',
            action: {
              enabled: true,
              type: 'page',
              value: 'online-offers',
              appearance: 'link',
            },
            icon: 'v2/heroic/shopping-bag',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'eStore',
            action: {
              enabled: true,
              type: 'page',
              value: 'estore',
              appearance: 'link',
            },
            icon: 'v2/heroic/truck',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Travel',
            action: {
              enabled: true,
              type: 'page',
              value: 'travel',
              appearance: 'link',
            },
            icon: 'v2/custom/plane-alt',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Dining',
            action: {
              enabled: true,
              type: 'page',
              value: 'dining',
              appearance: 'link',
            },
            icon: 'v2/box-icons/bx-dish',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Cinema',
            action: {
              enabled: true,
              type: 'page',
              value: 'cinema',
              appearance: 'link',
            },
            icon: 'v2/box-icons/bx-movie-play',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Community Hub',
            action: {
              enabled: true,
              type: 'page',
              value: 'community-hub',
              appearance: 'link',
            },
            icon: 'v2/heroic/location-marker',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Competitions',
            action: {
              enabled: true,
              type: 'page',
              value: 'competitions',
              appearance: 'link',
            },
            icon: 'v2/heroic/star',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Pre Sales',
            action: {
              enabled: true,
              type: 'page',
              value: 'pre-sales',
              appearance: 'link',
            },
            icon: 'v2/heroic/ticket',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Automotive',
            action: {
              enabled: true,
              type: 'page',
              value: 'automotive',
              appearance: 'link',
            },
            icon: 'plain/car-v2',
          },
        ],
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Rec Room',
        action: {
          enabled: true,
          type: 'page',
          value: 'rec-room',
        },
        icon: 'v2/box-icons/bx-message-detail',
        children: [
          {
            id: nanoid(),
            enabled: true,
            label: 'Newsfeed',
            action: {
              enabled: true,
              type: 'page',
              value: 'rec-feed-posts',
              appearance: 'link',
            },
            icon: 'v2/heroic/newspaper',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Announcements',
            action: {
              enabled: true,
              type: 'page',
              value: 'rec-feed-posts',
              params: {
                feedId: 'announcements',
              },
              appearance: 'link',
            },
            icon: 'v2/heroic/speakerphone',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Groups',
            action: {
              enabled: true,
              type: 'page',
              value: 'rec-groups',
              appearance: 'link',
            },
            icon: 'v2/heroic/user-group',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Events',
            action: {
              enabled: true,
              type: 'page',
              value: 'rec-events-feed',
              appearance: 'link',
            },
            icon: 'v2/heroic/calendar',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Awards',
            action: {
              enabled: true,
              type: 'page',
              value: 'rec-awards',
              appearance: 'link',
            },
            icon: 'v2/box-icons/bx-trophy',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Hi-5',
            action: {
              enabled: true,
              type: 'page',
              value: 'rec-hi5',
              appearance: 'link',
            },
            icon: 'v2/custom/hi-5',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Perks',
            action: {
              enabled: true,
              type: 'page',
              value: 'rec-perks',
              appearance: 'link',
            },
            icon: 'v2/box-icons/bx-happy-heart-eyes',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Saved Posts',
            action: {
              enabled: true,
              type: 'page',
              value: 'rec-feed-posts',
              params: {
                feedId: 'saved-posts',
              },
              appearance: 'link',
            },
            icon: 'v2/heroic/bookmark',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'My Posts',
            action: {
              enabled: true,
              type: 'page',
              value: 'rec-feed-posts',
              params: {
                feedId: 'my',
              },
              appearance: 'link',
            },
            icon: 'v2/heroic/pencil',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Reported Posts',
            action: {
              enabled: true,
              type: 'page',
              value: 'rec-feed-posts',
              params: {
                feedId: 'reported-posts',
              },
              appearance: 'link',
            },
            icon: 'v2/heroic/exclamation',
          },
        ],
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Health',
        action: {
          enabled: true,
          type: 'page',
          value: 'hlth-mind-and-body-type',
        },
        description: 'Assessment and work towards a healthier you…',
        icon: 'v2/custom/health',
        children: [
          {
            id: nanoid(),
            enabled: false,
            label: 'My Wellness',
            action: {
              type: 'page',
              value: 'hlth-my-health',
            },
            icon: 'v2/box-icons/bx-donate-heart',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Mind & Body',
            action: {
              type: 'page',
              value: 'hlth-mind-and-body-type',
            },
            icon: 'v2/box-icons/bx-walk',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Health eBites',
            action: {
              type: 'page',
              value: 'hlth-health-ebits-feed',
            },
            icon: 'v2/box-icons/bx-bowl-hot',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Lifestyle Library',
            action: {
              type: 'page',
              value: 'hlth-lifestyle-library-feed',
            },
            icon: 'v2/box-icons/bx-book-content',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Professional Network',
            action: {
              type: 'page',
              value: 'hlth-professional-network-feed',
            },
            icon: 'v2/custom/network',
          },
        ],
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'QR Connect',
        action: {
          enabled: true,
          type: 'page',
          value: 'qr-connect',
        },
        description:
          'Scan, Check in and reserve your workspace whilst managing all your reservations in one place.',
        icon: 'v2/heroic/qrcode',
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Activity',
        action: {
          enabled: true,
          type: 'page',
          value: 'activity',
        },
        description:
          'View your transaction history and manage your payments or print invoices for your payment activity.',
        icon: 'v2/box-icons/bx-bar-chart-alt',
      },
    ],
  },
}
