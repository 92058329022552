import { useExtensions } from '/~/composables/extensions'

export default function () {
  const { modules: globalModules } = useExtensions()

  const modules = [
    { value: '', label: 'Not selected' },
    ...globalModules.value.map((item) => {
      return {
        value: item.moduleName,
        label: item.label,
      }
    }),
  ]

  return {
    name: 'menus',
    version: '0.0.1',
    types: [
      {
        name: 'settings',
        fields: [
          {
            type: 'boolean',
            name: 'divider',
            order: 50,
            props: {
              label: 'Show divider',
            },
          },
        ],
        extends: ['menu', 'with-icons'],
      },
      {
        name: 'top',
        label: 'header',
        extends: ['menu', 'with-icons'],
        fields: [
          {
            type: 'boolean',
            name: 'divider',
            order: 50,
            props: {
              label: 'Show divider',
            },
          },
          {
            type: 'select',
            name: 'appearance',
            props: {
              options: [
                {
                  label: 'Icon',
                  value: 'icon',
                },
                {
                  label: 'Text',
                  value: 'text',
                },
              ],
              label: 'Appearance',
            },
            default: 'icon',
          },
        ],
      },
      {
        name: 'left',
        fields: [
          {
            type: 'boolean',
            name: 'divider',
            order: 50,
            props: {
              label: 'Show divider',
            },
          },
        ],
        extends: ['menu', 'with-icons'],
      },
      {
        name: 'left-mobile',
        fields: [
          {
            type: 'boolean',
            name: 'divider',
            order: 50,
            props: {
              label: 'Show divider',
            },
          },
        ],
        extends: ['menu', 'with-icons'],
      },
      {
        name: 'bottom-bar',
        extends: ['menu', 'with-icons'],
      },
      {
        name: 'footer',
        extends: ['menu', 'with-icons'],
      },
      {
        name: 'login-footer',
        extends: 'menu',
      },
    ],
    presets: {
      menu: {
        fields: [
          {
            type: 'boolean',
            name: 'enabled',
            order: 10,
            default: true,
            props: {
              label: 'Enabled',
            },
          },
          {
            type: 'boolean',
            name: 'desktop',
            order: 10,
            default: true,
            props: {
              label: 'Show on desktop',
            },
          },
          {
            type: 'boolean',
            name: 'mobile',
            order: 10,
            default: true,
            props: {
              label: 'Show on mobile',
            },
          },
          {
            type: 'select',
            name: 'restrictedRoles',
            props: {
              options: [],
              label: 'Restricted roles',
              multiple: true,
            },
            default: [],
          },
          {
            type: 'select',
            name: 'attachedModule',
            props: {
              options: modules,
              label: 'Attached module',
            },
            default: '',
          },
          {
            type: 'input',
            name: 'label',
            order: 20,
            default: 'Menu Item',
            props: {
              label: 'Label',
            },
          },
          {
            type: 'textarea',
            name: 'description',
            order: 20,
            default: '',
            props: {
              label: 'Description',
            },
          },
          {
            type: 'input',
            name: 'bl-memberships',
            order: 20,
            default: '',
            props: {
              label: 'Black listed memberships',
            },
          },
          {
            type: 'action',
            name: 'action',
            order: 30,
            default: {
              type: 'page',
              value: '',
              appearance: 'link',
              color: 'primary',
            },
            props: {
              exclude: 'label|look',
              label: 'On click - desktop',
            },
          },
          {
            type: 'action',
            name: 'mobile-action',
            order: 30,
            default: {
              type: 'page',
              value: '',
              appearance: 'link',
              color: 'primary',
            },
            props: {
              exclude: 'label|look',
              label: 'On click - mobile',
            },
          },
        ],
      },
      'with-icons': {
        fields: [
          {
            type: 'icon',
            name: 'icon',
            props: {
              label: 'Icon',
            },
          },
        ],
      },
    },
  }
}
